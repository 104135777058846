import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { Container, Card, CardMedia, CardContent, Typography, CardActions, CardActionArea, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import c1 from './imgs/c1.JPEG'
import c2 from './imgs/cap2.png'
import c1p1 from './imgs/c1p1.png'
import c1p2 from './imgs/c1p2.png'
import c1p3 from './imgs/c1p3.png'
import c1p4 from './imgs/c1p4.png'
import c1p5 from './imgs/c1p5.png'
import c1p6 from './imgs/c1p6.png'
import c1p7 from './imgs/c1p7.png'
import c1p8 from './imgs/c1p8.png'
import c1p9 from './imgs/c1p9.png'
import c1p10 from './imgs/c1p10.png'
import c1p11 from './imgs/c1p11.png'
import c1p12 from './imgs/c1p12.png'
import c1p13 from './imgs/c1p13.png'
import c2p1 from './imgs/c2p1.png'
import c2p2 from './imgs/c2p2.png'
import c2p3 from './imgs/c2p3.png'
import c2p4 from './imgs/c2p4.png'
import c2p5 from './imgs/c2p5.png'
import c2p6 from './imgs/c2p6.png'
import c2p7 from './imgs/c2p7.png'
import c2p8 from './imgs/c2p8.png'
import c2p9 from './imgs/c2p9.png'
import c2p10 from './imgs/c2p10.png'
import c2p11 from './imgs/c2p11.png'
import c2p12 from './imgs/c2p12.png'
import './App.css';
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const theme = createTheme({
  // Customize your theme here
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messageSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function ShowCard({ title, description, logo, slides }) {
  const [open, setOpen] = React.useState(false);


  return (
    <Card
      sx={{ maxWidth: 345 }}
      onClick={() => setOpen(true)}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="200"
          image={logo}
          alt={title}
          sx={{ objectFit: 'cover', objectPosition: 'top' }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
          <ShowLightbox
            open={open}
            close={() => setOpen(false)}
            slides={slides}
          />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setOpen(true)}>Abrir</Button>
        </CardActions>
      </CardActionArea>
    </Card>
    )
}

function ShowLightbox({ open, close, slides }) {
  return (
    <>
      <Lightbox
        carousel={{ finite: true }}
        open={open}
        close={close}
        slides={slides} // Use the slides prop
        plugins={[Zoom]}
      />
    </>
  );
}

function Home() {
  usePageViews();

  const cap1 = [
    { src: c1p1 },
    { src: c1p2 },
    { src: c1p3 },
    { src: c1p4 },
    { src: c1p5 },
    { src: c1p6 },
    { src: c1p7 },
    { src: c1p8 },
    { src: c1p9 },
    { src: c1p10 },
    { src: c1p11 },
    { src: c1p12 },
    { src: c1p13 },
  ];

  const cap2 = [
    { src: c2p1 },
    { src: c2p2 },
    { src: c2p3 },
    { src: c2p4 },
    { src: c2p5 },
    { src: c2p6 },
    { src: c2p7 },
    { src: c2p8 },
    { src: c2p9 },
    { src: c2p10 },
    { src: c2p11 },
    { src: c2p12 },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ marginBottom: 0 }}>
          O Mamaco Consciente
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center" sx={{ marginBottom: 2 }}>
          De Miguel Araujo e Bruno Fonseca
        </Typography>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <ShowCard
              title="Capítulo I: Rotina"
              description="Onde os agiotas precisam lidar com o caso Jeorge Tyler."
              slides={cap1}
              logo={c1} />
          </Grid>
          <Grid item>
            <ShowCard
              title="Capítulo II: Carl Jabobs" 
              description="Apresentamos nosso antagonista, que depois de uma tragédia, inicia seu plano de vingança."
              slides={cap2}
              logo={c2} />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_title: document.title,
      page_location: window.location.href,
      page_domain: window.location.hostname,
    });
  }, [location]);
}

function App() {
  const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
]);

  return (
    <RouterProvider router={router} />
  )
}

export default App;
